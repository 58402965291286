<template>
    <div class="data">
        <el-row :gutter="10">
            <el-col :span="12">
                <div class="word-rank">
                    <div class="title">搜索关键词(前50个，单位:次)</div>
                    <div class="word-list">
                        <div class="item" v-for="item in words.list" :key="item.text">
                            <div class="text">
                                {{ item.text }}
                            </div>
                            <div class="progress-item">
                                <div :style="{width:item.width+'%'}" class="progress-bar"></div>
                            </div>
                            <div class="sum">
                                {{ item.sum }}
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="word-rank">
                    <div class="title">最近50次搜索内容</div>
                    <div class="word-list">
                        <el-table
                            stripe
                            border
                            :data="page.data"
                            style="width: 100%">
                            <el-table-column
                                label="序号"
                                width="70"
                            >
                                <template slot-scope="scope">
                                    {{scope.$index+1}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="搜索内容"
                            >
                                <template slot-scope="scope">
                                    <i class="el-icon-home"></i>
                                    <el-link @click="jump(scope.$index,scope.row)" type="primary">{{ scope.row.text }}</el-link>
                                </template>
                            </el-table-column>

                            <el-table-column
                                label="搜索时间"
                                width="180">
                                <template slot-scope="scope">
                                    <format-time :time="scope.row.time" format="Y-m-d H:i:s"></format-time>
                                </template>
                            </el-table-column>
                            <!--                <el-table-column-->
                            <!--                    label="操作"-->
                            <!--                    width="90">-->
                            <!--                    <template slot-scope="scope">-->
                            <!--                        <el-button size="mini" @click="removeAnswer(scope.$index,scope.row)" icon="el-icon-delete"-->
                            <!--                                   type="danger"></el-button>-->
                            <!--                    </template>-->
                            <!--                </el-table-column>-->
                        </el-table>
                    </div>
                </div>
            </el-col>
        </el-row>
        <!--        <div class="page">-->
        <!--            <el-pagination-->
        <!--                background-->
        <!--                @current-change="pageChange"-->
        <!--                :current-page.sync="search.pageNumber"-->
        <!--                layout="total,prev, pager, next"-->
        <!--                :total="page.total">-->
        <!--            </el-pagination>-->
        <!--        </div>-->
    </div>
</template>
<script>
import F from '@/fields/fields';
import FormatTime from "@/components/common/format-time.vue";
import Search from "@/components/common/search.vue";

export default {
    components: {
        FormatTime, Search,
    },
    computed: {
        showFields: function () {
            return F.get('answer', 'verify').fields;
        },
        searchFields: function () {
            return F.get('answer', 'search').fields;
        },
    },
    data() {
        return {
            baseUrl: '/bbs/search/sum',
            page: {
                data: [],
                total: 1,
            },
            words: {
                list: [],
            },
            search: {
                search: {},
                pageNumber: 1,
                type: '',
            }
        }
    },
    mounted() {
        this.getData();
    },
    watch: {},
    methods: {
        condition(search) {
            this.search.pageNumber = 1;
            this.search.search = search;
            this.getData();
        },
        pageChange: function () {
            this.getData();
        },
        reStoreKeyValue: function (key, value) {
            return this.$tools.reStoreKeyValue(key, value);
        },
        getData: function () {
            // this.search.pageNumber = this.page.currentPage;
            this.$bbs.get(this.baseUrl, {
                params: this.search,
            }).then(data => {
                let max = 0;
                this.words.list = data.data.sort.sort((a, b) => {
                    return a.sum - b.sum > 0 ? -1 : 1;
                }).map(v => {
                    max = Math.max(max, v.sum);
                    return v;
                }).map(v => {
                    let w = Math.floor(v.sum * 100 / (max * 1.05));
                    v.width = w < 5 ? 5 : w;
                    return v;
                });
                // this.page.total = data.data.total;
                this.page.data = data.data.all;
                // this.page.currentPage = data.data.pageNumber;
            });
        },
        removeAnswer(index, data) {
            let title = data.title;
            if (title > 10) {
                title = title.substr(0, 10) + '...';
            }
            this.$confirm('你真的要删除:' + title + '?', '操作确认').then(() => {
                this.$bbs.delete(this.baseUrl, {data: {answer_id: data.answer_id}, need: {error: true}}).then(() => {
                    this.page.data.splice(index, 1);
                    this.page.total--;
                    this.$message.success('删除成功!');
                });
            });
        },
        jump(index, data) {
            let a = document.createElement('a');
            a.target = '_blank';
            a.href='/index/search?title='+data.text;
            a.click();

        }
    }
}

</script>

<style lang="less" scoped>
.data{
    margin-top: 1.5rem;
}
.word-rank {
    .title {
        padding: 10px;
        margin-bottom: 20px;
        background-color: #fff;
    }

    .word-list {
        padding: 10px 0;
        background-color: #fff;
        .item {
            padding: 8px 0;
            align-items: center;
            display: flex;
            margin-bottom: 5px;

            .text {
                width: 100px;
                color: #333;
                padding-right: 5px;
                text-align: right;
                flex-shrink: 0;
            }

            .progress-item {
                flex: 1;
                background-color: rgba(66, 74, 81, 0.41);
                border-radius: 10px;
                height: 6px;

                .progress-bar {
                    width: 50%;
                    border-radius: 10px;
                    transition: all ease-in-out 0.3s;
                    background-image: linear-gradient(90deg, #2574ea 0, #02cffe);
                    height: 100%;
                }
            }

            .sum {
                width: 50px;
                text-align: center;
                flex-shrink: 0;
            }
        }
    }
}

</style>
